import { AxiosInstance } from "axios"

export type ContactFormData = {
  name: string
  email: string
  subject?: string
  message: string
}

export class SendEmailApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  execute = (data: ContactFormData) =>
    this.client.post("/email/api/mail/contact-form", data)
}

import axios, { AxiosRequestConfig } from "axios"
import cfg from "../core/utils/config"

const createClient = (config: AxiosRequestConfig) => axios.create(config)

export const getApiClient = () => {
  const config = {
    baseURL: cfg.api.baseUrl,
    headers: {
      "Ocp-Apim-Subscription-Key": cfg.api.subscriptionKey,
      "Content-Type": "application/json",
    },
  }

  return createClient(config)
}

import * as React from "react"
import { getApiClient } from "../../../api/client"
import { SendEmailApi } from "../../../api/services/sendEmail"

type ApiContextProviderProps = { children: React.ReactNode }
export interface ApiContextInterface {
  sendEmailApi: SendEmailApi
}
const ApiContext = React.createContext<ApiContextInterface | null>(null)

const ApiContextProvider = ({ children }: ApiContextProviderProps) => {
  const client = getApiClient()
  const sendEmailApi = new SendEmailApi(client)
  const value = { sendEmailApi }
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export { ApiContextProvider, ApiContext }

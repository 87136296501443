import config from "../utils/config"

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export type ContentType = "article"
export type ShareMethods =
  | "linkedin"
  | "twitter"
  | "facebook"
  | "whatsapp"
  | "email"

export type ContentSelectEventParameters = {
  content_type: ContentType
  item_id: string
}

export type GenerateLeadEventParameters = {
  lead_type: string
}

export type LinkClickEventParameters = {
  link_type: "navigation"
  link_url: string
  link_name: string
  link_location: "header" | "footer" | "drawer" | "page"
}

export type ShareEventParameters = {
  method: ShareMethods
  content_type: ContentType
  item_id: string
}

class TagManager {
  onFormSubmissionEvent = (parameters: GenerateLeadEventParameters) =>
    this.sendEvent("generate_lead", parameters)
  onLinkClickEvent = (parameters: LinkClickEventParameters) =>
    this.sendEvent("link_click", parameters)
  onContentSelectEvent = (parameters: ContentSelectEventParameters) =>
    this.sendEvent("select_content", parameters)
  onShareEvent = (parameters: ShareEventParameters) =>
    this.sendEvent("share", parameters)

  private sendEvent = (event: string, payload: any) => {
    // We don't want analytics data from the staging environment
    if (config.isStaging) return

    const message = { event: event, ...payload }
    typeof window !== "undefined" &&
      typeof window.dataLayer !== "undefined" &&
      window.dataLayer.push(message)
  }
}

export default TagManager

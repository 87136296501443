import React from "react"
import { GatsbyBrowser } from "gatsby"
import { TagManagerContextProvider } from "../core/contexts/tagManager"
import { FirebaseContextProvider } from "../core/contexts/firebase"

import "@fontsource/ibarra-real-nova"
import "../styles/globals.css"
import { ApiContextProvider } from "../core/contexts/api"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <TagManagerContextProvider>
      <ApiContextProvider>
      <FirebaseContextProvider>{element}</FirebaseContextProvider>
      </ApiContextProvider>
    </TagManagerContextProvider>
  )
}

export default wrapRootElement
